import React from 'react';
import { IonHeader, IonToolbar, IonContent, IonSegmentButton, IonSegment, IonPage, IonButtons, IonBackButton, IonButton, IonIcon, IonText, IonList, IonItem, IonLabel, IonListHeader, IonRow, IonCol, IonInput } from '@ionic/react';
import { connect } from '../data/connect';
import { withRouter, RouteComponentProps } from 'react-router';
import * as selectors from '../data/selectors';
import { starOutline, star, share, calendar, cloudDownload, thumbsUpOutline, thumbsUp, sendOutline } from 'ionicons/icons';
import './SessionDetail.scss';
import { addFavorite, removeFavorite } from '../data/sessions/sessions.actions';
import { Session } from '../models/Schedule';
import SpeakerItem from '../components/SpeakerItem';
import { Speaker } from '../models/Speaker';

interface OwnProps extends RouteComponentProps { };

interface StateProps {
  session?: Session;
  favoriteSessions: number[],
};

interface DispatchProps {
  addFavorite: typeof addFavorite;
  removeFavorite: typeof removeFavorite;
}

type SessionDetailProps = OwnProps & StateProps & DispatchProps;

const SessionDetail: React.FC<SessionDetailProps> = ({ session, addFavorite, removeFavorite, favoriteSessions }) => {

  if (!session) {
    return <div>Session not found</div>
  }

  const isFavorite = favoriteSessions.indexOf(session.id) > -1;
  
  const toggleFavorite = () => { 
    isFavorite ? removeFavorite(session.id) : addFavorite(session.id);
  };
  const addCalendarSession = () => {
    console.log("añadir al calendario");
  };
  const sessionClick = (text: string) => { 
    console.log(`Clicked ${text}`);
  };

  return (
    <IonPage id="session-detail-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/schedule"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => toggleFavorite()}>
              {isFavorite ?
                <IonIcon slot="icon-only" icon={star}></IonIcon> :
                <IonIcon slot="icon-only" icon={starOutline}></IonIcon>
              }
            </IonButton>
            <IonButton onClick={() => addCalendarSession()}>
              <IonIcon slot="icon-only" icon={calendar}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding">
          <h1>{session.name}</h1>
          {session.tracks.map(track => (
            <span style={{backgroundColor:track.split('&&&')[1]}} key={track} className={`session-track-${track.toLowerCase()}`}>{track.split('&&&')[0]}</span>
          ))}
          < br/>
          < br/>
          <IonText color="medium">
            {session.timeStart} &ndash; {session.timeEnd}
            <br />
            {session.location}
            <br /><br />
            {session.speakerNames.map(speaker => (
            <a >{speaker}</a>
            ))}
            {/*session.speakerNames.join(",")*/}
          </IonText>
          <br />
          <br />
          <div dangerouslySetInnerHTML={ { __html: session.description } }></div>
          <br />
          <br />
        </div>
        <IonRow>
          <IonCol size="10">
            <IonItem fill="outline">
              <IonLabel position="floating">Hacer pregunta</IonLabel>
              <IonInput placeholder="Ingrese su pregunta, si alguien ya la realizó agregale un me gusta!"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="2">
            <IonButton expand="block">
              <IonIcon  icon={sendOutline} slot="icon-only" />
            </IonButton>
          </IonCol>
      </IonRow>
        <IonList>
          <IonListHeader>
            <IonLabel><h1><strong>Preguntas</strong></h1></IonLabel>
          </IonListHeader>
          <IonItem key={1}>
            <IonLabel>
              <h2>Pregunta 1</h2>
              <p>10 Me gusta</p>
            </IonLabel>
            <IonButton fill='clear' slot="end">
              <IonIcon icon={thumbsUp} slot="start" />
              Me gusta
            </IonButton>
          </IonItem>
          <IonItem key={2}>
            <IonLabel>
              <h2>Pregunta 1</h2>
              <p>7 Me gusta</p>
            </IonLabel>
            <IonButton fill="outline" slot="end" disabled={true}>
              <IonIcon icon={thumbsUpOutline} slot="start" />
              Me gusta
            </IonButton>
          </IonItem>
          <IonItem key={3}>
            <IonLabel>
              <h2>Pregunta 1</h2>
              <p>5 Me gusta</p>
            </IonLabel>
            <IonButton fill="outline" slot="end" disabled={true}>
              <IonIcon icon={thumbsUpOutline} slot="start" />
              Me gusta
            </IonButton>
          </IonItem>
          <IonItem key={4}>
            <IonLabel>
              <h2>Pregunta 1</h2>
              <p>5 Me gusta</p>
            </IonLabel>
            <IonButton fill="outline" slot="end" disabled={true}>
              <IonIcon icon={thumbsUpOutline} slot="start" />
              Me gusta
            </IonButton>
          </IonItem>
          <IonItem key={5}>
            <IonLabel>
              <h2>Pregunta 1</h2>
              <p>5 Me gusta</p>
            </IonLabel>
            <IonButton fill="outline" slot="end" disabled={true}>
              <IonIcon icon={thumbsUpOutline} slot="start" />
              Me gusta
            </IonButton>
          </IonItem>
          <IonItem key={6}>
            <IonLabel>
              <h2>Pregunta 1</h2>
              <p>5 Me gusta</p>
            </IonLabel>
            <IonButton fill="outline" slot="end" disabled={true}>
              <IonIcon icon={thumbsUpOutline} slot="start" />
              Me gusta
            </IonButton>
          </IonItem>
        </IonList>
        {/*<IonList>
          <IonItem onClick={() => sessionClick('watch')} button>
            <IonLabel color="primary">Watch</IonLabel>
          </IonItem><IonItem onClick={() => sessionClick('add to calendar')} button>
            <IonLabel color="primary">Agregar al calendario</IonLabel>
        </IonItem><IonItem onClick={() => sessionClick('mark as unwatched')} button>
            <IonLabel color="primary">Mark as Unwatched</IonLabel>
          </IonItem>
          <IonItem onClick={() => sessionClick('download video')} button>
            <IonLabel color="primary">Download Video</IonLabel>
            <IonIcon slot="end" color="primary" size="small" icon={cloudDownload}></IonIcon>
        </IonItem>
          <IonItem onClick={() => sessionClick('leave feedback')} button>
            <IonLabel color="primary">Dejar Comentario</IonLabel>
          </IonItem>
        </IonList>*/}
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    session: selectors.getSession(state, OwnProps),
    favoriteSessions: state.data.favorites
  }),
  mapDispatchToProps: {
    addFavorite,
    removeFavorite
  },
  component: withRouter(SessionDetail)
})