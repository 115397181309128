import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonItem, IonLabel, IonSelect, IonPopover, IonText, IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonRow, IonAvatar } from '@ionic/react';
import './About.scss';
import { ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';
import AboutPopover from '../components/AboutPopover';
import { format, parseISO } from 'date-fns';

interface AboutProps { }

const About: React.FC<AboutProps> = () => {

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState<MouseEvent>();
  const [location, setLocation] = useState<'madison' | 'austin' | 'chicago' | 'seattle'>('madison');
  const [conferenceDate, setConferenceDate] = useState('2047-05-17T00:00:00-05:00');

  const selectOptions = {
    header: 'Select a Location'
  };

  const presentPopover = (e: React.MouseEvent) => {
    setPopoverEvent(e.nativeEvent);
    setShowPopover(true);
  };

  function displayDate(date: string, dateFormat: string) {
    return format(parseISO(date), dateFormat);
  }

  return (
    <IonPage id="about-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={presentPopover}>
                <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="about-header">
          {/* Instead of loading an image each time the select changes, use opacity to transition them */}
          <div className="about-image madison" style={{'opacity': location === 'madison' ? '1' : undefined}}></div>
        </div>
        <IonCard className="ticket-card ion-text-center ion-justify-content-center ion-align-items-center">
      <IonCardHeader className="ion-text-center">
        <IonAvatar className="profile-avatar">
          <img
            src="https://i.pravatar.cc/300?img=56"
            alt="Profile"
          />
        </IonAvatar>
        <IonCardTitle className="attendee-name">Andrés Poveda</IonCardTitle>
        <IonCardSubtitle className="attendee-role">CTO</IonCardSubtitle>
        <IonCardSubtitle className="company-name">eSimposio</IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="12" className="ion-text-center">
              <IonCardSubtitle className="badge-title">PARTICIPANTE</IonCardSubtitle>
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="12" className="ion-text-center">
              <img
                src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://www.example.com"
                alt="QR Code"
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        {/*<IonButton expand="block" color="primary" className="action-button">
          Add to Wallet
        </IonButton>*/}
      </IonCardContent>
    </IonCard>
        
      </IonContent>

      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}
      >
        <AboutPopover dismiss={() => setShowPopover(false)} />
      </IonPopover>
    </IonPage>
  );
};

export default React.memo(About);